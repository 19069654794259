<template>
    <!--  Keep the if, it's there to prevent the login screen from showing up on the dash upon logging in while it attempts to unmount - some sort of weird dom thing idk what happens. -->
    <div class="login-body" v-if="$store.state.initialized == false">
        <div class="login-form">
            <img class="logo" src="@/assets/px/signin-logo.jpg" alt="MobexHealthHub" title="MobexHealthHub" />
            <ValidationObserver ref="form">
                <form @submit.prevent="submit">
                    <h2>Sign In</h2>
                    <div>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email *"
                            v-model="email"
                            label="Email "
                            required
                            rules="email"
                        />
                    </div>
                    <div>
                        <Input
                            type="password"
                            true_type='password'
                            name="password"
                            id="password"
                            class="fullwidth relative"
                            placeholder="Password *"
                            v-model="password"
                            label="Password "
                            required
                        />
                    </div>

                    <button class="login-submit" type="submit" style="background: var(--shaded-box-color)">
                        <span v-if="!loading">Submit</span>
                        <Loading
                            :outerHeight="'21px'"
                            :outerWidth="'21px'"
                            :height="'21px'"
                            :width="'21px'"
                            :color="'white transparent transparent transparent'"
                            :borderWidth="'3px'"
                            v-else
                        />
                    </button>
                    <!-- <button type="submit">submit</button> -->
                </form>
            </ValidationObserver>
            <div class="top-15 bottom-15">
                <button class="text-link no-bg" id="signInForgotPassword" @click.prevent="openPwReset" v-if="showFailureOptions">Forgot Password?</button>
            </div>
            <div class="login-cookies align-center " :class="showFailureOptions ? '':'hidden'">
                <p class="dark-text">
                    <span class="material-icons-outlined">error_outline</span> Having trouble signing in?
                </p>
                <button @click="clearCookies">
                    <span class="material-icons-outlined">delete_sweep</span> Clear Cookies
                </button>
                
            </div>
        </div>
        <Modal v-if="pwResetModalOpen" @close="closePwReset" :hasCloseButton="true">
            <h3>Forgot Password</h3>
            Please enter the email you created your account with. You will receive an email if entered email matches the email address on file.
            <Input type="email" v-model="pwResetEmail" label="" ref="forgotPwInput"></Input>
            <div class="align-right top-20">
                <Button class="secondary" @click.prevent="closePwReset" id="cancelPWReset">Cancel</Button>
                <Button class="primary" @click.prevent="pwReset" :disabled="pwResetDisabled" id="submitPWReset">
                <span v-if="!pwResetDisabled">Submit</span>
                    <Loading
                    :outerHeight="'21px'"
                    :outerWidth="'21px'"
                    :height="'21px'"
                    :width="'21px'"
                    :color="'white transparent transparent transparent'"
                    :borderWidth="'3px'"
                    v-else
                    />
                </Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    // import Input from '@/components/general/validatedInputs/Input.vue';
    import { auth } from '@/util/apiRequests';
    import Loading from '@/components/general/loading/loading';
    import Modal from '@/components/general/modals/Modal';
    import { cognitoSignIn } from '../../util/cognitoAuthUtil';
    import { users } from '@/util/apiRequests';
    import { API } from 'aws-amplify';
    import { getSeniors, getProfile } from '../../util/graphql/queries';

    export default {
        components: { ValidationObserver, Loading, Modal },
        data() {
            return {
                email: '',
                password: '',
                loading: false,
                showFailureOptions: false,
                pwResetModalOpen: false,
                pwResetEmail: '',
                pwResetDisabled: false,
                familyId: null,
                contactId: null,
                seniorId: null
            };
        },
        methods: {
            async openPwReset() {
                this.pwResetModalOpen = true;
                this.$nextTick(() => {
                    this.$refs?.forgotPwInput?.$el?.children[1]?.focus()
                })
            },
            closePwReset() {
                this.pwResetModalOpen = false;
            },
            async pwReset(){
                this.pwResetDisabled = true;

                await this.$api.post(auth.forgotPassword(), {email: this.pwResetEmail})

                // make post request to reset password
                setTimeout(() => {
                    this.pwResetDisabled = false;
                    this.pwResetEmail = '';
                    this.$toasted.success("Successful!")
                    this.closePwReset();
                }, 1000);
            },
            async clearCookies() {
                const res = await this.$api.post(auth.clearCookies());

                if (res.status >=200 && res.status <300){
                    this.$toasted.success('Cookies Cleared');
                } else {
                    this.$toasted.error('Error Clearing Cookies');
                }

                document.cookie = 'chirp_notes_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            },
            async getFamily() {
                this.contactId = localStorage.getItem('sessionContactId');
                this.familyId = localStorage.getItem('familyId');
                
            },
            async submit() {
                this.loading = true;
                const form = this.$refs.form;
                const isValid = await form.validate();
                if (!isValid) {
                    this.loading = false;
                    return;
                }
                try {

                    let email = this.email.toLowerCase().trim();

                    const response = await this.$api.post(auth.checkRole(), {
                        email: email,
                        password:this.password
                    })

                    if(response.data.isSuperAdmin){
                        await this.$router.replace({ name: 'SelectCompany', params: {email: email, password: this.password}, replace: true });
                    }

                    else{
                        const res = await this.$api.post(auth.signIn(), {
                            email: email,
                            password: this.password,
                            host: window.location.host
                        })
    
                        // note that errors for this are caught in the api file, in the interceptor
                        if (res?.status === 200) {
                            const session = await cognitoSignIn(email,this.password)
                            const {data:{getSeniors:membersList}}=await API.graphql({query: getSeniors})
                            const {data:{getProfile:profileDetails}}=await API.graphql({query: getProfile})
                            // console.log(profileDetails, "profile")
                            localStorage.setItem( 'sessionContactId', session.username );
                            if( profileDetails.families && profileDetails.families[0] ) {
                                localStorage.setItem( 'familyId', profileDetails.families[0].familyId );
                            }
                            await this.$store.commit('persistRelatedClients',membersList);
                            let loggedInUserDetail = await this.getLoggedInUserDetail(res.data.user.id);
                            let user = { ...res.data.user, ...loggedInUserDetail };
                            this.$store.commit('persistUser', user);
                            this.$store.commit('persistBuildId', res.data.buildId);
                            await this.$router.replace({ name: 'Dashboard' });
                            // await this.$router.push({name: 'Dashboard'});
                        } else {
                            this.showFailureOptions = true;
                        }
                    }
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                }
            },
            async getLoggedInUserDetail(userId) {
                try {
                    const res = await this.$api.get(users.getUserDetails(userId));
                    return { user_detail: res.data };

                } catch (e) {
                    console.error(e);
                }
            }
        }
    };
</script>
