import { Auth } from 'aws-amplify'
export const cognitoSignIn = async (email, password) => {
    try {
        return await Auth.signIn(email, password)
        // await Auth.updateUserAttributes(session);
    }
    catch (err) {
        console.error("Cognito Sign in error",err)
    }
}
